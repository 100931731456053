import React, { useState, useEffect } from 'react';
import './css/swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Autoplay, FreeMode, Navigation, Thumbs } from 'swiper/modules';

const Slider = ({ sliderdata }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [mainSwiper, setMainSwiper] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (sliderdata && sliderdata.length > 0) {
            const images = sliderdata.map(item => item.image);
            const imagePromises = images.map(src => new Promise(resolve => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve();
            }));
            Promise.all(imagePromises).then(() => setLoading(false));
        }
    }, [sliderdata]);

    const handleThumbClick = (index) => {
        if (mainSwiper !== null) {
            mainSwiper?.slideTo(index);
        }
    };

    const Slides = sliderdata?.map((item, index) => {
        return (
            <SwiperSlide key={index}>
                <img src={item?.image} loading='lazy' className="w-100 fixed-height-image" alt="" />
            </SwiperSlide>
        );
    });

    return (
        <>
            {loading ? (
                <div className="container">
                    <Swiper
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={4}
                        autoplay={{
                            delay: 6000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                    >
                        {[...Array(4)].map((_, index) => (
                            <SwiperSlide key={index}>
                                <div className="skeleton" style={{ height: '100px' }}></div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <>
                    <Swiper
                        onSwiper={(swiper) => setMainSwiper(swiper)}
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        autoplay={{
                            delay: 6000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[Autoplay, Navigation, Thumbs]}
                        className="mySwiper2"
                    >
                        {Slides}
                    </Swiper>

                    <div className="m-2"></div>
                    <div className='container'>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={true}
                            spaceBetween={10}
                            slidesPerView={4}
                            autoplay={{
                                delay: 6000,
                                pauseOnMouseEnter: true,
                                disableOnInteraction: false,
                            }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            {sliderdata?.map((item, index) => (
                                <SwiperSlide key={index} onClick={() => handleThumbClick(index)}>
                                    <img src={item.image} loading='lazy' className="w-100 fixed-height-image" alt="" style={{ cursor: 'pointer' }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
        </>
    );
};

export default Slider;
