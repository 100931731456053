import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, ListGroup, Pagination } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ApiUrl } from "../../API/ApiUrl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventCard = styled(ListGroup.Item)`
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 576px) {
    padding: 0.75rem;
  }
`;

const Title = styled.h5`
  margin-bottom: 0.5rem;
`;

const Date = styled.small`
  color: #6c757d;
  font-size: 0.875em;
`;

const Content = styled.p`
  margin-top: 0.5rem;
  font-size: 14px;
`;

const NoData = styled.p`
  text-align: center;
  margin-top: 1rem;
`;

const goBack = () => {
    window.location.href = "/";
};

const SelectedNews = () => {

    const search = useLocation().search;
    const encryptedId = new URLSearchParams(search).get("newsid");
    const newsid = parseInt(atob(decodeURIComponent(encryptedId)));

    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const eventsPerPage = 5;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
                const eventsData = response?.data?.data?.parishnewsdata || [];
                const selectedEvents = eventsData?.filter(
                    (item) => item?.category_id === 16 && item.id === newsid
                );
                setEvents(selectedEvents);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [newsid]);

    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = events?.slice(indexOfFirstEvent, indexOfLastEvent);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: events?.images?.length > 1,
    };


    return (
        <Container className="mt-3">
            <h3 className="text-center">Parish News</h3>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                </div>
            ) : (
                <>
                    {events.length === 0 ? (
                        <NoData>No data available</NoData>
                    ) : (
                        <ListGroup>
                            {currentEvents?.map((event) => (
                                <EventCard key={event?.id}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div>
                                                <Title style={{ fontWeight: "bold" }}>
                                                    {event?.title}
                                                </Title>
                                                <Date style={{ fontWeight: "bold" }}>
                                                    <i className="fa fa-calendar"></i>&nbsp;&nbsp;&nbsp;
                                                    {event?.eventdate}
                                                    <br />
                                                </Date>
                                                <Content>
                                                    <p
                                                        dangerouslySetInnerHTML={{ __html: event?.content }}
                                                        style={{ textAlign: "justify" }}
                                                    />
                                                </Content>
                                                <Link to={'/photo-gallery'}>
                                                    View More Photos
                                                </Link>

                                                <Link to={'/video-gallery'} className="ms-3">
                                                    Watch More Videos
                                                </Link>

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <Slider {...settings}>
                                                {event?.images?.map((image, index) => (
                                                    <div key={index}>
                                                        <img
                                                            src={image ?? "assets/images/noimage.jpg"}
                                                            alt={`slide-${index}`}
                                                            className="img-fluid"
                                                            style={{
                                                                borderRadius: "10px",
                                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                                                maxWidth: "100%",
                                                                height: "auto",
                                                            }}
                                                            onMouseOver={(e) =>
                                                                (e.currentTarget.style.transform = "scale(1.05)")
                                                            }
                                                            onMouseOut={(e) =>
                                                                (e.currentTarget.style.transform = "scale(1)")
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </EventCard>
                            ))}
                        </ListGroup>
                    )}
                    <Pagination className="mt-3">
                        {Array.from(
                            { length: Math.ceil(events.length / eventsPerPage) },
                            (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            )
                        )}
                    </Pagination>
                    <br />
                    <center>
                        <button
                            onClick={goBack}
                            className="btn btn-primary btn-sm"
                            id="btnmore"
                        >
                            Go Back
                        </button>
                    </center>
                </>
            )}
        </Container>
    );
};

export default SelectedNews;