import React from "react";
const MassTime = () => {
  return (
    <>
      <div className="col-lg-12 mb-3">
        <div className="section-title text-center">
          <h4 className="our-title">Mass Timings</h4>
        </div>
        <div className="timeline container">
          <div className="row">
            <div className="col-lg-6">
              <div className="event mass-dates">
                <div className="event-icon event-icon-weekdays">
                  <i className="fa fa-calendar" />
                </div>
                <div className="event-details" title="Weekdays">
                  <h5 className="mb-4">Masses in English</h5>

                  <h6>WEEKDAYS</h6>
                  <p>6.00 a.m. - Lower Church</p>
                  <p>11.00 a.m. - Lower Church (Tue, Thu &amp; Sat)</p>
                  <p>6.30 p.m. - Lower Church</p>
                  <br />
                  <h6>SUNDAYS</h6>
                  <p>6.00 a.m. - Lower Church</p>
                  <p>9.00 a.m. - Lower Church</p>
                  <p>9.00 a.m - Catechism Mass</p>
                  <p>11.00 a.m - Lower Church (Eng &amp; Tamil)</p>
                  <p>6.00 p.m. - Upper Church</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event mass-dates">
                <div className="event-icon event-icon-sundays">
                  <i className="flaticon-college" />
                </div>
                <div className="event-details" title="Sundays">
                  <h5 className="mb-4">Masses in Tamil</h5>
                  <h6>தமிழ் திருவழிபாடு ( வார நாட்கள் )</h6>

                  <p>காலை 6.40 - கீழ் கோவில்</p>
                  <p>காலை 11.00 - (திங்கள், புதன் மற்றும் வெள்ளி )</p>
                  <p>மாலை 6.30 - கீழ் கோவில்</p>
                  <br />
                  <h6>தமிழ் திருவழிபாடு ( ஞாயிறு )</h6>

                  <p>காலை 6.00 - மேல் கோவில்</p>
                  <p>காலை 7.30 - மேல் கோவில் மற்றும் கீழ் கோவில்</p>
                  <p>காலை 8.00 - சி.சி.பாக்கம்</p>
                  <p>காலை 9.00 - மறைக்கல்வி மாணவர்கள்</p>
                  <p>
                    காலை 11.00 - (பிரதி ஞாயிறு ஆங்கிலம் / தமிழ் ) <br />
                    கீழ் கோவில்
                  </p>
                  <p>மாலை 6.00 - கீழ் கோவில்</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MassTime;
