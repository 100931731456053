import React from 'react';

const Scrollbar = () => {
    return (
        <>
            <div className="marquee-container">
            <div className="marquee">Thank You Lord for the Blood. Thank You Lord for the Cross. Thank you Lord for 125 Years of Grace</div>
            </div>
        </>
    );
};

export default Scrollbar;
