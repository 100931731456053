import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Header = ({ menudata }) => {
  const location = useLocation();
  const url = location.pathname;

  return (
    <>
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="header-left-content">
                <span id="emailLink">
                  <i className="fa fa-envelope text-white"></i>
                  <a
                    href={`mailto:peramburlourdesshrine@gmail.com`}
                    id="emailAnchor"
                    className="text-white"
                  >
                    &nbsp; peramburlourdesshrine@gmail.com
                  </a>
                </span>
                <span id="phoneLink">
                  <i className="fa fa-phone text-white"></i>
                  <a
                    href={`tel:+91 44-2670 1100`}
                    id="phoneAnchor"
                    className="text-white"
                  >
                    &nbsp; +91 44-2670 1100
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-area nav-bg-2">
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu">
              <div className="logo">
                <Link to="/" className="newmaillogo">
                  <img
                    src="/assets/images/newlogo1.png"
                    className="mobile-logo"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="desktop-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="newmaillogo">
                <img
                  src="/assets/images/newlogo1.png"
                  className="main-logo"
                  alt="logo"
                />
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto">
                  {menudata?.map((menuItem, index) => (
                    <li key={index} className="nav-item">
                      {menuItem.children ? (
                        <Link
                          to={menuItem.url}
                          className={`nav-link dropdown-toggle  ${menuItem.children.some((child) =>
                            url.includes(child.url)
                          )
                            ? "activemain"
                            : ""
                            }`}
                        >
                          {menuItem.label}{" "}
                          {menuItem.children && (
                            <i className="fa fa-angle-down ml-2 mb-2" />
                          )}
                        </Link>
                      ) : (
                        <Link
                          to={menuItem.url}
                          className={`nav-link ${url === menuItem.url ? "activemain" : ""
                            }`}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {menuItem.label}
                        </Link>
                      )}
                      {menuItem.children && (
                        <ul className="dropdown-menu">
                          {menuItem.children?.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className={`nav-item ${url === subItem.url ? "active" : ""
                                }`}
                            >
                              {subItem.subchildren ? (
                                <Link
                                  to={subItem.url}
                                  className={`nav-link dropdown-toggle`}
                                >
                                  {subItem.label}
                                </Link>
                              ) : (
                                <Link
                                  to={subItem.url}
                                  className={`nav-link ${url === subItem.url ? "activemain" : ""
                                    }`}
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {subItem.label}
                                </Link>
                              )}
                              {subItem.subchildren && (
                                <ul className="dropdown-menu" id="submenu-scrollbar">
                                  {subItem.subchildren?.map(
                                    (subChildItem, subChildIndex) => (
                                      <li
                                        key={subChildIndex}
                                        className={`nav-item ${url === subChildItem.url
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <Link
                                          to={subChildItem.url}
                                          className="nav-link"
                                        >
                                          {subChildItem.label}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="others-option-for-responsive">
          <div className="container">
            <div className="dot-menu">
              <b>Menu</b>
              <br />
              <div className="inner">
                <div className="icon">
                  <i
                    className="ri-menu-3-fill"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBothOptions"
                    aria-controls="offcanvasWithBothOptions"
                    title="Click to View Menu"
                  />
                  <div
                    className="offcanvas offcanvas-start"
                    data-bs-scroll="true"
                    tabIndex={-1}
                    id="offcanvasWithBothOptions"
                    aria-labelledby="offcanvasWithBothOptionsLabel"
                  >
                    <div className="offcanvas-header">
                      <h5
                        className="offcanvas-title"
                        id="offcanvasWithBothOptionsLabel"
                      >
                        <Link to="/" className="newmaillogo">
                          <img
                            src="/assets/images/newlogo1.png"
                            className="mobile-logo"
                            alt="logo"
                          />
                        </Link>
                      </h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      />
                    </div>
                    <div className="offcanvas-body">
                      <ul className="navbar-nav ms-auto">
                        {menudata?.map((menuItem, index) => (
                          <li key={index} className="nav-item">
                            {menuItem.children ? (
                              <>
                                <a
                                  href={menuItem.url}
                                  className={`nav-link dropdown-toggle ${menuItem.children.some((child) =>
                                    url.includes(child.url)
                                  )
                                    ? "activemain"
                                    : ""
                                    }`}
                                  data-toggle="dropdown"
                                >
                                  {menuItem.label}
                                  <i className="fa fa-angle-down ml-2 mb-2" />
                                </a>
                                <ul className="dropdown-menu p-3">
                                  {menuItem.children.map(
                                    (subItem, subIndex) => (
                                      <li
                                        key={subIndex}
                                        className={`nav-item ${url === subItem.url ? "active" : ""
                                          }`}
                                      >
                                        {subItem.subchildren ? (
                                          <>
                                            <a
                                              href={subItem.url}
                                              className="nav-link dropdown-toggle"
                                              data-toggle="dropdown"
                                            >
                                              {subItem.label}
                                              <i className="fa fa-angle-down ml-2 mb-2" />
                                            </a>
                                            <ul className="dropdown-menu p-2">
                                              {subItem.subchildren.map(
                                                (
                                                  subChildItem,
                                                  subChildIndex
                                                ) => (
                                                  <li
                                                    key={subChildIndex}
                                                    className={`nav-item ${url === subChildItem.url
                                                      ? "active"
                                                      : ""
                                                      }`}
                                                  >
                                                    <a
                                                      href={subChildItem.url}
                                                      className="nav-link"
                                                    >
                                                      {subChildItem.label}
                                                    </a>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </>
                                        ) : (
                                          <a
                                            href={subItem.url}
                                            className={`nav-link ${url === subItem.url
                                              ? "activemain"
                                              : ""
                                              }`}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {subItem.label}
                                          </a>
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </>
                            ) : (
                              <a
                                href={menuItem.url}
                                className={`nav-link ${url === menuItem.url ? "activemain" : ""
                                  }`}
                              >
                                {menuItem.label}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
