import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import MainLayout from "./layout";
import ContactUs from "./Pages/Contact/ContactUs";
import History from "./Pages/OurParish/History";
import PrayerRequest from "./Pages/PrayerRequest/PrayerRequest";
import MeetOurPriest from "./Pages/OurParish/MeetOurParish";
import Magazine from "./Pages/OurParish/MassTime";
import AllNews from "./Pages/LatestNews/AllNews";
import AllEvents from "./Pages/LatestNews/AllEvents";
import VideoGallery from "./Pages/Gallery/VideoGallery";
import PhotoGallery from "./Pages/Gallery/PhotoGallery";
import ParishPriestMessage from "./Pages/HomeSub/ParishPriestMessage";
import NewsLetter from "./Pages/Calendar/NewsLetter";
import AllParishJubilee from "./Pages/LatestNews/AllParishJubilee";
import AllParishNews from "./Pages/LatestNews/AllParishNews";
import SelectedNews from "./Pages/LatestNews/SelectedNews";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<MainLayout />}>
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="parish-groups" element={<History />} />
          <Route path="history" element={<History />} />
          <Route path="mass-timings" element={<History />} />
          <Route path="db-school" element={<History />} />
          <Route path="savio-school" element={<History />} />
          <Route path="semi-boarding" element={<History />} />
          <Route path="old-age-home" element={<History />} />
          <Route path="marriage-bureau" element={<History />} />
          <Route path="jubilee" element={<History />} />
          <Route path="shrine-stall" element={<History />} />
          <Route path="legion-mary-tamil" element={<History />} />
          <Route path="legion-mary-english" element={<History />} />
          <Route path="salesian-corporators-tamil" element={<History />} />
          <Route path="salesian-corporators-english" element={<History />} />
          <Route path="adma" element={<History />} />
          <Route path="vincent-de-paul-society" element={<History />} />
          <Route path="altar-server" element={<History />} />
          <Route path="english-youth-group" element={<History />} />
          <Route path="tamil-youth-group" element={<History />} />
          <Route path="vdb" element={<History />} />
          <Route path="dumb-box-team" element={<History />} />
          <Route path="liturgical-committee" element={<History />} />
          <Route path="volunteer-committee" element={<History />} />
          <Route path="catechism" element={<History />} />
          <Route path="parish-pastoral-council" element={<History />} />
          <Route path="substation-ccpakkam" element={<History />} />
          <Route path="religious-article-stall" element={<History />} />
          <Route path="lourdes-shrine-choirs" element={<History />} />
          <Route path="prayer-request" element={<PrayerRequest />} />
          <Route path="thanks-giving" element={<PrayerRequest />} />
          <Route path="pious-association" element={<History />} />
          <Route path="know-your-priest" element={<MeetOurPriest />} />
          <Route path="magazines" element={<Magazine />} />
          <Route path="ournews" element={<AllNews />} />
          <Route path="ourevents" element={<AllEvents />} />
          <Route path="video-gallery" element={<VideoGallery />} />
          <Route path="photo-gallery" element={<PhotoGallery />} />
          <Route path="priest-message" element={<ParishPriestMessage />} />
          <Route path="newsletter" element={<NewsLetter />} />
          <Route path="all-parish-jubilee" element={<AllParishJubilee />} />
          <Route path="all-parish-news" element={<AllParishNews />} />
          <Route path="specific-news" element={<SelectedNews />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
