import React from 'react';
import './Magazine.css';

const Magazine = () => {
    const pdfPath = '/assets/images/img/magazine.pdf';

    return (
        <div className="container">
            <div className="pdf-header">
                <h4 className='our-title'>Our e-magazines</h4>
            </div>
            <center>
                <div className='row'>
                    {/* <div className='col-lg-4'>
                        <a href={pdfPath} target='_blank' rel='noreferrer' className='pdf-magazine-link'>
                            <img className='pdf-magazine' src="assets/images/magazine.png" alt="" />
                            <h5 className='m-2'>June - July 2023</h5>
                        </a>

                    </div> */}
                    {/* <div className='col-lg-4'>
                        <a href={pdfPath} target='_blank' rel='noreferrer' className='pdf-magazine-link'>
                            <img className='pdf-magazine' src="assets/images/magazine.png" alt="" />
                            <h5 className='m-2'>June - July 2023</h5>
                        </a>
                    </div> */}
                    <center>
                        <div className='col-lg-4'>
                            <a href={pdfPath} target='_blank' rel='noreferrer' className='pdf-magazine-link'>
                                <img className='pdf-magazine' src="assets/images/magazine.png" alt="" />
                                <h5 className='m-2'>June - July 2023</h5>
                            </a>
                        </div>
                    </center>
                </div>

            </center>
        </div>
    );
};

export default Magazine;