import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../API/ApiUrl";
import "./Priest.css";

const MeetOurParish = () => {
  const [allPriest, setAllPriest] = useState([]);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/teammembers`)
      .then((response) => {
        setAllPriest(response?.data?.teams);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="section-title text-center mt-3">
        <h4 className="our-title mb-3">Our Priests</h4>
      </div>
      <div className="container">
        <div className="row">
          {allPriest?.map((item, index) => (
            <div className="col-lg-3 d-flex" key={index} id="sliderpriest">
              <div id="priestnew">
                <div id="priestnew-inner">
                  <div id="priestnew-front">
                    <img
                      src={item?.media_url}
                      alt="No Data"
                      className="priestallimg"
                      style={{ width: '255px', height: '295px' }}
                    />
                    <br />
                    <br />
                    <h5>{item?.title}</h5>
                    <p>{item?.role}</p>
                  </div>
                  <div id="priestnew-back">
                    <h6>Priest Details</h6>
                    <h5 className="mt-3">{item?.title}</h5>
                    <p>{item?.role}</p>
                    <center>
                      <div className="social-icons">
                        <a href={item?.facebook} className="facebook">
                          <i className="fa fa-facebook-f"></i>
                        </a>
                        <a href={item?.twitter} className="twitter">
                          <i className="fa fa-twitter"></i>
                        </a>
                        <a href={item?.instagram} className="instagram">
                          <i className="fa fa-instagram"></i>
                        </a>
                        <a href={item?.linkedin} className="linkedin">
                          <i className="fa fa-linkedin"></i>
                        </a>
                        <a href={item?.youtube} className="youtube">
                          <i className="fa fa-youtube"></i>
                        </a>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MeetOurParish;
