import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
const Footer = ({ footerdata }) => {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const goTopButton = document.querySelector(".go-top");

    if (goTopButton) {
      goTopButton.addEventListener("click", handleScrollToTop);
    }

    return () => {
      if (goTopButton) {
        goTopButton.removeEventListener("click", handleScrollToTop);
      }
    };
  }, []);

  return (
    <>
      <div className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet">
                <div className="row">
                  <div className="col-12">
                    <Link to={"/"}>
                      <img
                        src="assets/images/img/grotto.jpg"
                        title="Grotto"
                        className="main-logos"
                        loading="lazy"
                        style={{ width: "60%", height: "auto", boxShadow: "2px 2px 5px #000", borderRadius: "10px", marginTop: "10px", borderWidth: "2px", borderColor: "white", borderStyle: "solid", marginBottom: "10px", marginRight: "10px", marginLeft: "10px", padding: "5px", background: "white" }}
                        alt="grotto"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widjet">
                <div className="section-headings">
                  <h3 className="text-white entry-title mobile-response-footer ">
                    Quick Links
                  </h3>
                  <p className="bottom-border"></p>
                </div>
                <div className="list">
                  <ul className="quick-links">
                    <li>
                      <Link to={"/history"}>
                        <i className="fa fa-angle-right"></i> History
                      </Link>
                    </li>
                    <li>
                      <Link to={"/know-your-priest"}>
                        <i className="fa fa-angle-right"></i> Our Priests
                      </Link>
                    </li>
                    <li>
                      <Link to={"/mass-timings"}>
                        <i className="fa fa-angle-right"></i> Mass Timings
                      </Link>
                    </li>
                    <li>
                      <Link to={"/parish-groups"}>
                        <i className="fa fa-angle-right"></i> Parish Zones
                      </Link>
                    </li>
                    <li>
                      <Link to={"/pious-association"}>
                        <i className="fa fa-angle-right"></i> Parish
                        Associations
                      </Link>
                    </li>
                    <li>
                      <Link to={"/all-parish-jubilee"}>
                        <i className="fa fa-angle-right"></i> Parish Jubilee
                      </Link>
                    </li>
                    <li>
                      <Link to={"/video-gallery"}>
                        <i className="fa fa-angle-right"></i> Parish Videos
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widjet">
                <div className="section-headings">
                  <h3 className="text-white entry-title mobile-response-footer">
                    Contact Us
                  </h3>
                  <p className="bottom-border"></p>
                </div>
                {footerdata ? (
                  <span id="emailLink">
                    <p className="text-white mt-2">
                      <span className="theme-clr">
                        <i className="fa fa-map-marker"></i>
                      </span>
                      &nbsp;&nbsp;{footerdata?.address}
                    </p>
                    <p className="text-white">
                      <span className="theme-clr">
                        <i className="fa fa-phone"></i>
                      </span>
                      &nbsp;&nbsp;
                      <a
                        href={`tel:${footerdata?.mobile}`}
                        id="phoneAnchor"
                        className="text-white"
                      >
                        &nbsp;{footerdata?.mobile}
                      </a>
                    </p>
                    <p className="text-white">
                      <span className="theme-clr">
                        <i className="fa fa-envelope"></i>
                      </span>
                      &nbsp;&nbsp;
                      <a
                        href={`mailto:${footerdata?.email}`}
                        id="emailAnchor"
                        className="text-white"
                      >
                        &nbsp;{footerdata?.email}
                      </a>
                    </p>
                  </span>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
              <h6 className="magazine">Subscribe to our e-magazines</h6>
              <p className="magazine-border"></p>
              <div className="newsletter-signup mt-2">
                <form autoComplete="off">
                  <input
                    type="email"
                    id="email-data"
                    placeholder="Enter your Email"
                    required
                  />
                  <button type="submit" className="btn-submit">
                    Subscribe
                  </button>
                </form>
              </div>
              <br />
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet">
                <div className="section-headings">
                  <h3 className="text-white entry-title">Our Location</h3>
                  <p className="bottom-border"></p>
                </div>
                <div className="list">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.843732084267!2d80.23618708440311!3d13.109084278911553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265b73211d32b%3A0xda5ec2bc77c1c7e2!2sOur%20Lady%20of%20Lourdes%20Shrine!5e0!3m2!1sen!2sin!4v1712839252208!5m2!1sen!2sin"
                    width="100%"
                    height={200}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="loreto"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                  <br />
                  <p className="text-white footer-text mt-2" id="footcopynew">
                    &copy; {currentYear}{" "}
                    <a
                      href="https://boscosofttech.com/"
                      target="_blank"
                      id="footcopynew"
                      className="text-white"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Bosco Soft Technologies.
                    </a>
                    &nbsp;All rights reserved.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="copy"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="go-top">
        <i className="ri-arrow-up-s-line" />
        <i className="ri-arrow-up-s-line" />
      </div>
    </>
  );
};

export default Footer;
