import React from 'react';

const Striptext = () => {
    return (
        <>
            <div style={{ background: '#f2f2f2', padding: '10px', fontSize: '14px' }} className='mb-1 mt-2'>
                <div className='container'>
                    <div className='row align-items-center'>
                        {/* Left Side Text */}
                        <div className='col-lg-10 col-md-12'>
                            <h5 className='text-center mb-2 mt-2' style={{ color: '#900404' }}>CHILD SAFETY POLICY</h5>
                            <p className='text-justify text-black'>
                                At Lourdes Shrine Perambur, we hold the care, safety and wellbeing of children and young people as a central and fundamental responsibility of our parish. Our Catholic commitment is drawn from and inherent in the teaching and mission of Jesus Christ, with love, justice and the sanctity of each human person at the heart of the gospel.
                            </p>
                            <p className='text-justify text-black'>
                                If you or any child you know has/is experiencing any form of clergy or other abuse within our parish please contact the parish priest immediately. Your confidentiality is ensured.
                            </p>
                        </div>

                        {/* Right Side Image */}
                        <div className='col-lg-2 col-md-12 text-center mt-2'>
                            <img src="assets/images/gold.jpg" style={{ height: "9rem", width: "25rem" }} alt="crackers" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Striptext;
