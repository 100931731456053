import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../API/ApiUrl';
import './newsletter.css';

const NewsLetter = () => {
    const [newsLetter, setNewsLetter] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cachedNewsLetter = localStorage.getItem('cachedNewsLetter');
        if (cachedNewsLetter) {
            setNewsLetter(JSON.parse(cachedNewsLetter));
            setLoading(false);
        }

        axios.get(`${ApiUrl}/get/Newsletter`)
            .then((res) => {
                const data = res?.data?.data || [];
                localStorage.setItem('cachedNewsLetter', JSON.stringify(data));
                setNewsLetter(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching newsLetter:', error);
                setLoading(false);
            });
    }, []);

    const filterData = Array.isArray(newsLetter) ? newsLetter.filter((item) => item.category_id === 15) : [];

    const formatMonth = (dateString) => {
        const dateParts = dateString.split('-');
        const monthIndex = parseInt(dateParts[1]) - 1;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[monthIndex];
        return month;
    };

    const formatDate = (dateString) => {
        const dateParts = dateString.split('-');
        const day = parseInt(dateParts[0]);
        return day;
    };

    return (
        <>
            {loading ? (
                <div className="loading-spinner text-center mt-5">
                    <b>Loading...</b>
                </div>
            ) : (
                <section className="event-section section-padding">
                    <div className="container">
                        <div className="section-title text-center">
                            <h4 className="title">News Letter</h4>
                        </div>
                        {filterData.length === 0 ? (
                            <div className="no-newsletter-message">
                                No newsletters available.
                            </div>
                        ) : (
                            <div className="row">
                                {filterData?.map((item) => (
                                    <div className="col-md-6" key={item.id}>
                                        <div className="event-wrapper">
                                            <div className="date pull-left text-center">
                                                {formatDate(item.eventdate)}
                                                <span className="month">{formatMonth(item.eventdate)}</span>
                                            </div>
                                            <div className="wrapper-content">
                                                <h4>
                                                    <a href={item.file_url}>
                                                        {item.title}<br/><br/>
                                                    </a>
                                                    <a href={item.file_url} className="download-icon" style={{ float: "right" }} download title='Download File'>
                                                        <img src="assets/images/img/download.webp" className='download' alt="download" style={{ height: "50px", width: "50px" }} />
                                                    </a>
                                                </h4>
                                                <span className="time small-text">
                                                    <i className="fa fa-calendar" aria-hidden="true" /> {item.eventdate}
                                                </span>
                                                <span className="location small-text">
                                                    <i className="fa fa-user" aria-hidden="true" /> Admin
                                                </span>
                                                <span className="location small-text">
                                                    &nbsp;&nbsp;&nbsp;<i className='fa fa-list-alt' aria-hidden="true" /> {item.category_name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
            )}
        </>
    );
};

export default NewsLetter;
