import React from 'react'
import { Link } from 'react-router-dom'

const ChurchHistory = () => {
    return (
        <>
            <div className="section-title text-center">
                <h4 className="our-title">Lourdes Shrine Perambur</h4>
                <h6>(Built as a replica of Lourdes in France)</h6>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-8">
                        <p style={{ textAlign: "justify" }}>
                            In 1879, in commemoration of the silver jubilee of the proclamation of the dogma of the Immaculate Conception, Fr. H. Hennessey, Parish Priest of St. Andrew’s Church, Vepery, Madras, built a chapel at Perambur. This chapel was blessed and dedicated to Our Lady of Lourdes in 1880 and served the spiritual needs of the catholics then residing in the locality. In 1903, Perambur was raised to the status of an independent parish. In 1903, with a catholic population of 5000 people and a church capable of accommodating only about 500 it was obvious that any scheme to enlarge the existing church was not at all feasible. Legionaries of Mary reported many instances of lapsed Catholics who had regularly stood out the Sunday masses for years. It was this juncture that the First Salesian priest, Fr. Michael Murray, arrived in December 1953. The privilege, however, of making Our Lady of Lourdes Shrine at Perambur into a famous place of pilgrimage fell to the lot of worthy sons of Don Bosco. In 1928, the Archdiocese of Madras was entrusted to the Salesian Congregation and Perambur came under their care. Fr. Murray was a priest with a rare combination of Irish inspiration and Salesian zeal which soon had the parish humming with activities. In many other ingenious ways he brought Mary into thousands of homes and collected money to start building a new church. It was Fr Murray who launched the idea of making Perambur a national shrine and place of pilgrimage.<Link to={"/history"} id="brown-button" className="brown-button">
                                Read more
                            </Link>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <iframe
                            width="100%"
                            height="250"
                            src="https://www.youtube.com/embed/0U_PltlVM2E?si=Z829vmpkzr2vLIKh"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            title="Lourdes Shrine Perambur"
                            style={{
                                border: "none",
                                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                padding: "4px",
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChurchHistory
