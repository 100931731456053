import React from 'react';
import './css/Buttons.css';
import { Link } from 'react-router-dom';

const Buttons = () => {
    const buttonsData = [
        // {id:1, text: 'Offer a Mass', iconClass: 'fa fa-file', link: '' },
        { id:2,text: 'Offer a Prayer Request', iconClass: 'fa fa-edit', link: 'prayer-request' },
        // { id:3, text: 'Make a Donation', iconClass: 'fa fa-hand-holding-usd', link: '' },
        // { text: 'Subscribe to our e-magazine', iconClass: 'fa fa-newspaper-o', link: 'magazines' },
    ];
    return (
        <center>
            <div className="container">
                <div className="row mobile-grid">
                    {buttonsData?.map((button, index) => (
                        <div className="col" key={index}>
                            {/* <a
                                className="custom-buttons custom-buttons-link custom-size-sm"
                                href={button?.link}
                                
                            >
                                <span className="custom-buttons-content-wrapper">
                                    
                                    <span className="custom-buttons-text">
                                    <font>{button?.text}</font>
                                </span>

                                </span>
                            </a> */}
                            <Link to={button?.link}><button type="button" className="btn btn-primary" >{button?.text}</button></Link>
                        </div>
                    ))}
                    
                </div>
            </div>
        </center>
    );
};

export default Buttons;
