import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/Priest.css';
import { Link } from 'react-router-dom';

const AllPriest = ({ allpriestdata }) => {
    return (
        <>
            <div className="section-title text-center mt-3">
                <h4 className="our-title">Our Priests</h4>
            </div>
            <br />
            <div className="container">
                <Slider
                    dots={false}
                    infinite={true}
                    // autoplay={true}
                    autoplaySpeed={3000}
                    pauseOnHover={true}
                    pauseOnFocus={true}
                    swipeToSlide={true}
                    speed={500}
                    slidesToShow={4}
                    slidesToScroll={1}
                    responsive={[
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                infinite: true,
                                dots: true
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                initialSlide: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}
                >
                    {allpriestdata?.map((item, index) => (
                        <div className='col-lg-3 d-flex' key={index} id="sliderpriest">
                            <div id="priestnew">
                                <div id="priestnew-inner">
                                    <div id="priestnew-front">
                                        <Link to={`/know-your-priest`}>
                                            <img
                                                src={item.media_url || 'images/priest/nouser.jpg'}
                                                alt="No Data"
                                                className="priestallimg"
                                                style={{ width: '255px', height: '295px' }}
                                            />
                                        </Link>
                                        <br />
                                        <br />
                                        <h5>{item.title}</h5>
                                        <p className='text-size'>{item.role}</p>
                                    </div>
                                    <div id="priestnew-back">
                                        <h6>Priest Details</h6>
                                        <h5 className="mt-3"><Link to={`/know-your-priest`}>{item.title}</Link></h5>
                                        <p>{item.role}</p>
                                        <div className="social-icons">
                                            <a href={item.facebook} className='facebook'><i className="fa fa-facebook-f"></i></a>
                                            <a href={item.twitter} className='twitter'><i className="fa fa-twitter"></i></a>
                                            <a href={item.instagram} className='instagram'><i className="fa fa-instagram"></i></a>
                                            <a href={item.linkedin} className='linkedin'><i className="fa fa-linkedin"></i></a>
                                            <a href={item.youtube} className='youtube'><i className="fa fa-youtube"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            &nbsp;
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default AllPriest;
