import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoComponent = () => {
    const [videoData, setVideoData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideoData = async () => {
            const cachedData = localStorage.getItem('LatestvideoData');

            if (cachedData) {
                console.log("Using cached data from localStorage.");
                setVideoData(JSON.parse(cachedData));
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems', {
                    params: {
                        part: "snippet",
                        playlistId: "UUb_oIWxckR_bC9Tyi4y9mtA",
                        maxResults: 10,
                        key: "AIzaSyD9lHZ1Irk6gz1Ur6QW4BR8QsVmsm66fCA",
                    },
                });
                const uniqueVideos = response.data.items.filter((item, index, self) =>
                    index === self.findIndex(t => t.snippet.resourceId.videoId === item.snippet.resourceId.videoId)
                );

                setVideoData(uniqueVideos);
                localStorage.setItem('videoData', JSON.stringify(uniqueVideos));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching video data:', error);
                setError('There was a problem fetching video data. Please try again later.');
                setLoading(false);
            }
        };

        fetchVideoData();
    }, []);

    const redirectToYouTube = () => {
        window.open("https://youtube.com/@lourdesperambur1211?si=59UlIdgxhbSK8e2p", "_blank", "noreferrer noopener");
    };

    return (
        <div className="container">
            <h4 className='out-title text-center mb-2'>Parish Videos</h4>
            {loading && <p>Loading...</p>}
            {error && <p className="text-danger">{error}</p>}
            {!loading && !error && (
                <div className="row">
                    {videoData.map((item) => (
                        <div className="col-md-3" key={item.snippet.resourceId.videoId}>
                            <div className="single-mission">
                                <div className="mission-img">
                                    <iframe
                                        title={item.snippet.title}
                                        width="100%"
                                        height="220"
                                        src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`}
                                        frameBorder="0"
                                        allowFullScreen
                                        style={{
                                            border: "none",
                                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                            padding: "4px",
                                        }}
                                    ></iframe>
                                </div>
                                <h6 className='text-center mb-4'>{item.snippet.title}</h6>
                            </div>
                        </div>
                    ))}
                    <div className='text-center'>
                        <button onClick={redirectToYouTube} className='btn btn-primary btn-sm'>View More</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoComponent;
