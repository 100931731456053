import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiUrl } from '../../API/ApiUrl';

const History = () => {
  const location = useLocation();
  const url = location.pathname;
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const path = {
      '/history': 1,
      '/parish-groups': 2,
      '/mass-timings': 3,
      '/db-school': 4,
      '/savio-school': 5,
      '/semi-boarding': 6,
      '/old-age-home': 7,
      '/marriage-bureau': 8,
      '/shrine-stall': 9,
      '/magazines': 10,
      '/jubilee': 13,
      '/legion-mary-tamil': 14,
      '/salesian-corporators-tamil': 15,
      '/adma': 16,
      '/vincent-de-paul-society': 17,
      '/altar-server': 18,
      '/english-youth-group': 19,
      '/tamil-youth-group': 20,
      '/vdb': 21,
      '/dumb-box-team': 22,
      '/liturgical-committee': 23,
      '/volunteer-committee': 24,
      '/legion-mary-english': 25,
      '/salesian-corporators-english': 26,
      '/parish-pastoral-council': 27,
      '/substation-ccpakkam': 28,
      '/religious-article-stall': 29,
      '/catechism': 30,
      '/pious-association': 31,
      '/lourdes-shrine-choirs': 32,
    };
    setId(path[url] || null);
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/Pages`);
        const apiData = response?.data?.pages ?? [];
        setData(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (!data || data?.length === 0) {
    return (
      <div className="text-center mt-5">
        <b>No Data Available</b>
      </div>
    );
  }

  const filterdata = data?.filter((item) => item?.id === id);

  return (
    <div className="container subpage">
      <div className="row">
        <div className="col-lg-12">
          {filterdata?.map((item) => (
            <div key={item?.id}>
              <div className="section-title text-center">
                <h4 className="our-title mb-3 mt-3">{item?.title}</h4>
              </div>
              <div
                style={{ textAlign: 'justify' }}
                dangerouslySetInnerHTML={{
                  __html: `${item?.content}`,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default History;
