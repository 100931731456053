import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";

const News = () => {
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState([]);
  const [error, setError] = useState(null);
  const cache = useRef(null);

  // Fetch YouTube video data with caching
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchVideoData = useCallback(
    debounce(async () => {
      const cachedData = localStorage.getItem("youtubeVideoData");
      if (cachedData) {
        console.log("Using cached data from localStorage.");
        setVideoData(JSON.parse(cachedData));
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/playlistItems",
          {
            params: {
              part: "snippet",
              playlistId: "UUb_oIWxckR_bC9Tyi4y9mtA",
              maxResults: 1,
              key: "AIzaSyD9lHZ1Irk6gz1Ur6QW4BR8QsVmsm66fCA",
            },
          }
        );

        const uniqueVideos = response.data.items.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t.snippet.resourceId.videoId === item.snippet.resourceId.videoId
            )
        );

        console.log("Fetched new data.");
        setVideoData(uniqueVideos);
        setLoading(false);
        localStorage.setItem("youtubeVideoData", JSON.stringify(uniqueVideos));
        cache.current = uniqueVideos;
      } catch (error) {
        console.error("Error fetching video data:", error);
        setError("There was a problem fetching video data. Please try again later.");
        setLoading(false);
      }
    }, 300),
    []
  );

  // Fetch video data on mount
  useEffect(() => {
    fetchVideoData();
    return () => fetchVideoData.cancel();
  }, [fetchVideoData]);

  // Conditional rendering after hooks
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestVideo = videoData[0];

  return (
    <div className="events-area pb-40">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          {latestVideo && (
            <div className="col-lg-5 col-md-4">
              <iframe
                src={`https://www.youtube.com/embed/${latestVideo.snippet.resourceId.videoId}`}
                title={latestVideo.snippet.title}
                style={{
                  width: "31rem",
                  height: "14rem",
                  border: "none",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  padding: "4px",
                }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
              <p className="text-center" id="yt-title">{latestVideo.snippet.title}</p>
            </div>
          )}
          <div className="col-lg-4 col-md-4">
            <img src="assets/videos/mary.gif" alt="Center" style={{marginBottom:"50px", height:"14rem", width: "30rem", cursor: "pointer", border: "none", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)", padding: "4px"}} className="img-fluid" />
          </div>
          {/* Center dynamic content */}
          <>
            <div className="col-lg-3 col-md-4">
              <div className="row justify-content-center">
                <img
                  src="assets/images/jubileeprayer.png"
                  style={{
                    width: "15rem", height: "19rem", cursor: "pointer", border: "none",
                    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                    padding: "4px",
                  }}
                  alt="Jubilee Prayer"
                  data-bs-toggle="modal"
                  data-bs-target="#imageModal"
                  title="Click to enlarge image"
                />
              </div>
            </div>
            <div
              className="modal fade"
              id="imageModal"
              tabIndex={-1}
              aria-labelledby="imageModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="imageModalLabel">
                      Jubilee Prayer Image
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <center>
                      <img
                        src="assets/images/jubileeprayer.png"
                        style={{
                          width: "70%", height: "40rem",
                          padding: "4px",
                        }}
                        alt="Jubilee Prayer"
                      />
                    </center>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div >
  );
};

export default News;
