import React from "react";
const HomeBar = () => {
  const videos = [
    {
      id: 1,
      title: "18 Apparitions of Our Lady of Lourdes",
      src: "https://www.youtube.com/embed/TKglWqtiDOo?si=OR3R4DvzbKBPIvYX",
    }
  ];
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <iframe
            src="https://www.youtube.com/embed/2Xn1Bb697A0?si=rNwpjghDsvZfoGvo"
            title="YouTube video player"
            frameBorder="0"
            id="myIframe"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{
              width: "100%",
              height: "300px",
              border: "none",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              padding: "4px",
            }}
            className="mt-2"
          ></iframe>
          <p className="text-center mb-4" style={{ fontSize: "18px" }}>
            Watch Live: Lourdes Grotto at France
          </p>
        </div>
        <div className="section-title text-center">
          <h4 className="our-title mb-2">Lourdes in France</h4>
        </div>
        {videos?.map((video, index) => (
          <div className="col-md-12 mb-1" id="slideryoutube" key={index}>
            <iframe
              src={video?.src}
              title={video?.title}
              id="video-frames"
              style={{
                width: "100%",
                height: "300px",
                border: "none",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                padding: "4px",
              }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
            <p className="text-center">{video?.title}</p>
            <div className="related-videos">
              <h6 className="text-left text-primary" style={{ marginLeft: "29px", fontSize: "12px" }}>Watch more</h6>
              <div className="row">
                <div className="col-md-3">
                  <ul>
                    <a
                      href="https://www.youtube.com/embed/kLPY_bAwpZM?si=WGQl1JqZnWCoUlQI"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", fontSize: "12px" }}
                    >
                      St.Bernadette: A Life of Faith and Wonder
                    </a>
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul>
                    <a
                      href="https://www.youtube.com/embed/d5mTGN0gkO8?si=Bx3MX6lFeBR5nU5Z"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", fontSize: "12px" }}
                    >
                      Our Lady's Simple Message at Lourdes
                    </a>
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul>
                    <a
                      href="https://www.youtube.com/embed/VqkNs8lV7-c?si=ardahk4bvDLPA20u"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", fontSize: "12px" }}
                    >
                      Incorruptible body of Saint Bernadette
                    </a>
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul>
                    <a
                      href="https://www.youtube.com/embed/0U_PltlVM2E?si=Z829vmpkzr2vLIKh"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", fontSize: "12px" }}
                    >
                      Secret Behind the Miraculous Lourdes Water
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeBar;
