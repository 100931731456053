import React, { useEffect, useState, useRef } from 'react';
import './css/counter.css';

const Counter = () => {
  const counterData = {
    "members": {
      "number": 4222,
      "label": "Families",
    },
    "family": {
      "number": 14476,
      "label": "Members",
    },
    "zone": {
      "number": 19,
      "label": "Zones",
    },
    "anbiyam": {
      "number": 105,
      "label": "Anbiyams",
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const NumCounter = ({ maxCount, label }) => {
    const formattedCount = label === 'Families' || label === 'Members' ? maxCount.toLocaleString() : maxCount;

    return (
      <div className="counter">
        <h2 className="timer">{formattedCount}</h2>
        <p className="count-text">{label}</p>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row text-center">
        {isVisible && Object.keys(counterData).map((key) => (
          <div className="col" key={key}>
            <NumCounter
              maxCount={counterData[key].number}
              label={counterData[key].label}
            />
          </div>
        ))}
      </div>
      <div ref={sectionRef}></div>
    </div>
  );
};

export default Counter;
