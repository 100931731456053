import React from "react";

const ParishAnnouncement = ({ announcementdata }) => {

    const getFilteredAndSortedNews = (announcementdata) => {
        if (!announcementdata || announcementdata.length === 0) return null;
        const filteredAnnouncement = announcementdata.filter(event => event.category_id === 17);
        if (filteredAnnouncement.length === 0) return null;
        const sortedNews = filteredAnnouncement.sort((a, b) => new Date(b.date) - new Date(a.date));

        return sortedNews[0];
    };

    const latestNews = getFilteredAndSortedNews(announcementdata);

    return (
        <div className="events-area pb-40 mt-3">
            <div className="container">
                <div className="row justify-content-center align-items-center mt-3">
                    {/* Left side image */}
                    {latestNews && (
                        <div className="col-lg-4 col-md-6">
                            <div className="titles">
                                <h5 className="text-center mb-3">
                                    {latestNews.title}
                                </h5>
                            </div>
                            <img
                                src={latestNews.file_id}
                                alt="Left Side"
                                className="img-fluid"
                                data-bs-toggle="modal"
                                title="Click to enlarge image"
                                data-bs-target="#staticBackdrop"
                                style={{
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "8px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                    maxWidth: "100%",
                                    height: "auto",
                                    cursor: "pointer",
                                }}
                                onMouseOver={(e) =>
                                    (e.currentTarget.style.transform = "scale(1.05)")
                                }
                                onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            />
                        </div>
                    )}
                    {/* Center video */}
                    <div className="col-lg-4 col-md-4">
                        <img src="assets/videos/cloud.gif" alt="Center" style={{ height: "300px", width: "200px", marginLeft: "100px" }} className="img-fluid" />
                        <center>
                            <img src="assets/images/jubilee1.png" alt="Center" className="img-fluid" />
                        </center>
                    </div>
                    {/* Right side image */}
                    {latestNews && (
                        <div className="col-lg-4 col-md-6">
                            <div className="titles">
                                <h5 className="text-center mb-3">
                                    {latestNews.etitle}
                                </h5>
                            </div>
                            <img
                                src={latestNews.file_image}
                                alt="Right Side"
                                data-bs-toggle="modal"
                                title="Click to enlarge image"
                                data-bs-target="#staticBackdrop2"
                                className="img-fluid"
                                style={{
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "8px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                    maxWidth: "100%",
                                    height: "auto",
                                    cursor: "pointer",
                                }}
                                onMouseOver={(e) =>
                                    (e.currentTarget.style.transform = "scale(1.05)")
                                }
                                onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Modals */}
            {/* Left Side Modal */}
            <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                {latestNews?.title}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body text-center modal-scrollbar">
                            <img
                                src={latestNews?.file_id}
                                alt="Left Side"
                                className="img-fluid"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Side Modal */}
            <div
                className="modal fade"
                id="staticBackdrop2"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel2"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel2">
                                {latestNews?.etitle}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body text-center modal-scrollbar">
                            <img
                                src={latestNews?.file_image}
                                alt="Right Side"
                                className="img-fluid"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop2"
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParishAnnouncement;
